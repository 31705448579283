import React from 'react'

const AboutUs = () => {
  return (
    <div>
        <section id="about" className="about">

<div className="container" data-aos="fade-up" data-aos-delay="100">
  <div className="row align-items-xl-center gy-5">

    <div className="col-xl-5 content">
    <div className="section-title">
          <h2 className=' fw-bold fs-1 text-dark mb-0'>About US</h2>
        
        </div>
      <p>
AT VISKO REAL ESTATE PRIVATE LIMITED, we believe that the best investment on earth is earth. Real estate investment can bring predictable cash flow, tax advantages and portfolio diversification. It’s long been a reliable source of passive income, and real estate investing apps mean the market is more accessible than ever. We have introduced a new concept of ownership in the real estate sector, known as ‘fractional’ investing. Fractional investing allows a user to invest small amounts of money in real estate projects. In simple words, a property is divided into ‘fractions’ and is sold between multiple owners. Fractional investment in real estate has gained popularity as a modern and inclusive approach to property ownership. It provides investors with flexibility, diversification, and access to a wider range of investment opportunities in the real estate market.
</p>


    </div>

    <div className="col-xl-7">
      <div className="row gy-4 icon-boxes">

        <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
          <div className="icon-box">
            <i className="bi bi-buildings"></i>
            <h3>Time commitment</h3>
            <p>Real estate investing, especially managing rental properties, can be time-consuming. Investors need to be prepared to dedicate time and effort to managing their investments </p>
          </div>
        </div> 
        

        <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div className="icon-box">
            <i className="bi bi-clipboard-pulse"></i>
            <h3>Less Diversification</h3>
             <p>Investors investing huge amounts of money in a single property are not able to diversify their real estate portfolio    </p>
          </div>
        </div> 

        <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
          <div className="icon-box">
            <i className="bi bi-command"></i>
            <h3>Market Timing Risks</h3>
            <p>Timing the real estate market can be challenging. Purchasing properties at the peak of a market cycle may result in lower returns or potential losses if property values decline.</p>
          </div>
        </div>

        <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
          <div className="icon-box">
            <i className="bi bi-graph-up-arrow"></i>
            <h3>Liquidity Challenges</h3>
            <p>Real estate is generally considered less liquid than other investment assets. Selling a property can take time, and the process may be influenced by market conditions, location, and property type.</p>
          </div>
        </div> 
      </div>
    </div>

  </div>
</div>

</section>
    </div>
  )
}

export default AboutUs
